import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { Helmet } from 'react-helmet'
import Login from './Login'
import Recovery from './Recovery'
import Reset from './Reset'
import ResetResult from './ResetResult'
import Main from './Main'


const App = () => {
  const validateToken = () => {
    let token = Cookies.get('token')
    if (token === 'undefined') token = null
    let tokenValid = false
    if (token) {
      const decoded = jwt_decode(token)
      if (Date.now() < decoded.exp * 1000) {
        tokenValid = true
      }
    }
    return tokenValid
  }

  return (
    <>
      <Helmet>
        <title>Log In</title>
      </Helmet>
      <Router basename="/">
        <Route
          exact
          path="/"
          render={({ location }) => (validateToken() ? (
            <Redirect to={`/admin/`} />
          ) : (
            <Redirect to="/login" />
          ))}
        />
        <Route
          exact
          path="/en"
          render={({ location }) => (validateToken() ? (
            <Redirect to={`/admin/`} />
          ) : (
            <Redirect to="/login" />
          ))}
        />
        <Route
          path="/login"
          render={({ location }) => (validateToken() ? (
            <Redirect to={`/admin/`} />
          ) : (
            <Login />
          ))}
        />
        <Route
          path="/recovery"
          render={({ location }) => (validateToken() ? (
            <Redirect to={`/admin/`} />
          ) : (
            <Recovery />
          ))}
        />
        <Route
          path="/reset"
          render={({ location }) => (validateToken() ? (
            <Redirect to={`/admin/`} />
          ) : (
            <Reset />
          ))}
        />
        <Route
          path="/result"
          render={({ location }) => (validateToken() ? (
            <Redirect to={`/admin/`} />
          ) : (
            <ResetResult />
          ))}
        />
        <Route
          path="/admin"
          render={({ location }) => (validateToken() ? (
            <Main />
          ) : (
            <Redirect to={{ to: '/', state: { from: location } }} />
          ))}
        />
      </Router>
    </>
  )
}

export default App
