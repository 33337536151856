import React, { useEffect } from 'react'


const CloseButton = ({ setShowModal }) => {
  return (
    <div
      className="absolute top-0 right-0 flex items-center justify-center w-8 h-8 mt-4 mr-4 rounded-full cursor-pointer select-none hover:bg-primary text-primary hover:text-white"
      onClick={() => setShowModal(state => !state)}
    >
      <span className='text-xl md:mt-1'>×</span>
    </div>
  )
}

const Modal = ({
  children,
  showModal,
  setShowModal,
  background,
}) => {
  const handleKeyDown = ({ code }) => {
    if (showModal && code === "Escape") setShowModal(state => !state)
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [])

  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      setShowModal(false)
    }
  }

  useEffect(() => {
    if (showModal) {
      document.addEventListener('keydown', handleKeyPress)
      return () => {
        document.removeEventListener('keydown', handleKeyPress)
      }
    }
  }, [showModal])

  if (!showModal) return null

  return (
    <div className={`fixed top-0 right-0 z-40 flex items-center justify-center w-full h-full ${background}`}>
      <div className="absolute top-0 left-0 w-full h-full -z-10 bg-black/5" onClick={() => setShowModal(state => !state)}></div>
      {children}
    </div >
  )
}

export {
  CloseButton,
  Modal,
}