import React, { useState, useEffect, useRef } from 'react'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'
import { JsonEditor } from 'jsoneditor-react';
import Button from '../Button';

const template = `
  {
    "config": {
      "sort": { "_id": "asc" },
      "list": { "title": "Title" }
    },
    "fields": [
      {
        "name": "title",
        "validations": {
          "not_blank": "Required field"
        },
        "type": "text",
        "options": {
          "label": "Title"
        }
      }
    ]
  }
`

const Json = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: { label, markdown },
  validations,
  languages,
  innerForm,
  readMode,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable) || template
  )
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState(template)
  const [editorMode, setEditorMode] = useState('text')
  const jsonEditor = useRef(null)

  const onChangeText = v => {
    if (translatable) {
      value[language] = v
      setValue({ ...value })
    } else {
      setValue(v)
    }
    setCurrentValue(v)
  }

  useEffect(() => {
    try {
      if (currentValue && JSON.stringify(jsonEditor.current.jsonEditor.get()) !== currentValue) {
        jsonEditor.current.jsonEditor.set(JSON.parse(currentValue))
        jsonEditor.current.jsonEditor.expandAll()
      }
    } catch (e) {
      console.log('Error', e)
    }
  }, [currentValue])

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
  }, [value, language, translatable])

  useEffect(() => {
    if (defaultValue(name, storedData, translatable) === value) return
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  useEffect(() => {
    if (editorMode === 'tree')
    jsonEditor.current.jsonEditor.expandAll()
  }, [editorMode])

  const textarea = (
    <div key={editorMode} >
      <JsonEditor
        mode={ readMode ? 'view' : editorMode }
        ref={jsonEditor}
        value={currentValue ? JSON.parse(currentValue) : ''}
        onChange={e => {
          console.log('EEEE', e)
          onChangeText(JSON.stringify(e))
        }}
        name={name}
        className={`w-full px-2 py-1 ${
          showErrors && errors.length > 0 ? 'border border-error' : ''
        } rounded ${
          translatable && Object.keys(languages)[0] === language
            ? 'rounded-tl-none'
            : ''
        } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none resize-y`}
        placeholder={label}
        onError={e => {
          console.log('Error', e)
        }}
        htmlElementProps={{style: {height: 600}}}
      />
    </div>
  )

  const [notificationText, setNotificationText] = useState('')
  const [showJsonTextArea, setShowJsonTextArea] = useState(false)


  useEffect(() => {
    if (notificationText) {
      setTimeout(() => {
        setNotificationText('')
      }, 3000);
    }
  }, [notificationText])

  const copyJSON = (text) => {
    navigator.clipboard.writeText(JSON.stringify(jsonInput, undefined,2))
    setNotificationText(`Copied JSON`)
  }

  const insertJSON = () => {
    setShowJsonTextArea(false)
    if (jsonInput) {
      setNotificationText(`Inserted JSON`)
      onChangeText(JSON.stringify(jsonInput))
    }
  }

  const [jsonInput, setJsonInput] = useState(currentValue)

  useEffect(() => {
    if (currentValue) {
      setJsonInput(JSON.parse(currentValue))
    }
  }, [currentValue,showJsonTextArea])  

  return (
    <div className="h-full">
      {showJsonTextArea && <div className="fixed inset-0 z-40 flex items-center justify-center " style={{ backgroundColor: 'rgba(255,255,255,.8)' }}>
        <div className='w-1/2 bg-white border rounded border-gray'>
          <div className='flex flex-col items-end p-4 pt-2'>
            <span className="w-full cursor-pointer hover:text-primary hover:wght-semibold" onClick={copyJSON}>Copy JSON</span>
            <textarea name="" id="" cols="30" rows="20" className='w-full p-2 mb-2 font-mono text-xs border border-black rounded' value={JSON.stringify(jsonInput, undefined,2)} onChange={(e) => setJsonInput(JSON.parse(e.target.value))}></textarea>
            <div className='flex justify-end'>
              <span
                onClick={() => setShowJsonTextArea(false)}
                className="px-2 py-0.5 mx-0.5 text-red border border-red rounded cursor-pointer  bg-white hover:bg-grayLighter"
              >
                Cancel
              </span>
              <span
                onClick={insertJSON}
                className="px-2 py-0.5 mx-0.5 text-white rounded cursor-pointer  bg-primary hover:bg-black"
              >
                Insert
              </span>
            </div>
          </div>
        </div>
        </div>
      }
      {notificationText &&
        <span className='fixed z-50 px-2 py-1 text-sm border-2 rounded bottom-5 right-5 bg-purple'>{notificationText}</span>
      }
      { !readMode && 
        <div className="flex items-end justify-between">
          <div className='flex flex-col mb-2'>
            <span className='mb-2 mr-1'>Edit structure</span>
            <span className='flex'>
              <Button size="small" color={editorMode === "tree" ? 'primary' : 'white'} className='ml-0 mr-1' onClick={() => setEditorMode('tree')}>Tree</Button>
              <Button size="small" color={editorMode === "text" ? 'primary' : 'white'} className='ml-0 mr-1' onClick={() => setEditorMode('text')}>JSON</Button>
            </span>
          </div>
          <div className='flex'>
            <span className="h-full cursor-pointer hover:text-primary hover:wght-semibold" onClick={() => setShowJsonTextArea(true)}>Import JSON</span>
          </div>
        </div>
      }
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {textarea}
        </LanguageSelector>
      ) : (
        textarea
      )}
    </div>
  )
}

export default Json
