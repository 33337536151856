import React, { useState } from 'react'
import { Helmet } from "react-helmet"
import { useHistory } from 'react-router-dom'
import Import from "./Import"
import Cookies from "js-cookie"
import { useEffect } from "react"
import Loader from "./Loader"
import { CloseButton, Modal } from "./Modal"

const SelectWithSearch = ({ options, searchOption, handleSearch, selectedOption, handleOption, isFocused, handleFocus }) => {
  const filteredOptions = options.filter((option) => option.title.toLowerCase().includes(searchOption.toLowerCase()))

  return (
    <div className="mr-2">
      <input
        className="bg-white outline-none placeholder-gray py-1 px-1 text-sm block"
        type="text"
        placeholder="Search specie..."
        value={searchOption}
        onChange={handleSearch}
        onFocus={() => handleFocus(true)}
        onBlur={() => selectedOption?._id && handleFocus(false)}
      />
      <div className="max-h-64 overflow-auto">
        {isFocused && !selectedOption?._id && searchOption && filteredOptions.map((option) => (
          <div
            key={option._id}
            onClick={() => handleOption(option)}
            className="text-black cursor-pointer hover:wght-semibold bg-white py-2 text-sm pl-2 hover:bg-primaryLight"
          >
            {option.title}
          </div>
        ))}
      </div>
    </div>
  );
};

const ObservationsImport = () => {
  const history = useHistory()
  const [modelName] = useState('ObservationsImport')
  const [loading, setLoading] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [dataFields, setDataFields] = useState({})
  const [searchOption, setSearchOption] = useState('');
  const [selectedOption, setSelectedOption] = useState({});
  const [isFocused, setIsFocused] = useState(false);
  const [selectedFile, setSelectedFile] = useState()
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState('')
  const [observationsFetch, setObservationsFetch] = useState({
    savedObservationsNumber: 0,
    savedObservations: 0,
    totalObservationsNumber: 0,
    rejectedObservationsNumber: 0,
    rejectedRowNumbers: [],
    rejectedObservations: 0
  })

  const handleSearch = (e) => {
    setSearchOption(e.target.value);
    setSelectedOption('');
  };

  const handleOption = (option) => {
    setSearchOption(option.title);
    setSelectedOption(option);
  };

  useEffect(() => {
    (async () => {
      setLoading(true)
      try {
        const resp = await fetch(`${process.env.REACT_APP_API_URL}model-edit`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`
          },
          body: JSON.stringify({
            modelName
          })
        })

        const data = await resp.json()

        if (resp.status === 403) {
          Cookies.remove('token')
          history.push('/login')
        } else {
          setDataFields(data)
        }
      } catch (err) {
        console.log('🚀 ~ file: ObservationsImport.js:29 ~ getDataFields ~ err:', err)
        return { status: 400, data: {} }
      }
      setLoading(false)
    })()
  }, [history, modelName])

  useEffect(() => {
    if (selectedFile) {
      (async () => {
        const formData = new FormData();
        formData.append('modelName', modelName);
        formData.append('file', selectedFile);
        formData.append('selectedOption', selectedOption._id);
        setUploading(true)
        try {
          const resp = await fetch(`${process.env.REACT_APP_API_URL}model-observations-import`, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${Cookies.get('token')}`,
            },
            body: formData
          })

          const data = await resp.json()

          if (resp.ok) {
            setObservationsFetch(data)
            setShowModal(state => !state)
          } else if (resp.status === 403) {
            Cookies.remove('token')
            history.push('/login')
          } else {
            setError('Error with xls')
            setTimeout(() => setError(''), 3000);
          }
        } catch (err) {
          setError('Fetch error')
          setTimeout(() => setError(''), 3000);
          console.log('🚀 ~ file: ObservationsImport.js:29 ~ getDataFields ~ err:', err)
        }
        setUploading(false)
      })()
    }
  }, [history, modelName, selectedFile, selectedOption])

  return (
    <div>
      <Helmet>
        <title>List ObservationsImport</title>
      </Helmet>

      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl wght-semibold">List {modelName}</h2>
      </div>

      <Modal setShowModal={setShowModal} showModal={showModal}>
        <div className="relative w-1/3 pb-0 overflow-auto bg-white z-1 max-h-128">
          <CloseButton setShowModal={setShowModal} />
          <div className="m-8">
            <div className="text-left bg-white">
              <span className="relative wght-semibold text-xl">
                Import results
              </span>
            </div>

            <div className="text-left bg-white mt-4">
              <span className="relative">
                <span className="wght-semibold mr-2">
                  Saved observations:
                </span >
                {observationsFetch?.savedObservationsNumber}
              </span >
            </div>

            <div className="text-left bg-white mt-2">
              <span className="relative">
                <span className="wght-semibold mr-2">
                  Total observations:
                </span>
                {observationsFetch?.totalObservationsNumber}
              </span >
            </div>

            <div className="text-left bg-white mt-2">
              <span className="relative">
                <span className="wght-semibold mr-2">
                  Rejected observations:
                </span >
                {observationsFetch?.rejectedObservationsNumber}
              </span >
            </div>

            <div className="text-left bg-white mt-2 max-h-36 overflow-scroll">
              <span className="relative">
                <span className="wght-semibold mr-2">
                  Rejected row numbers:
                </span >
                {observationsFetch?.rejectedRowNumbers.join(', ') || 'Neither'}
              </span >
            </div>
          </div>
        </div>
      </Modal >

      <Loader loading={loading} className="mt-8" text="Loading...">
        {dataFields?.find &&
          <div>
            <span>Select specie:</span>
            <div className="flex">
              <SelectWithSearch
                options={dataFields.find?.Species}
                searchOption={searchOption}
                handleSearch={handleSearch}
                selectedOption={selectedOption}
                handleOption={handleOption}
                isFocused={isFocused}
                handleFocus={(focus) => setIsFocused(focus)}
              />

              <Import
                disabled={!selectedOption?._id}
                uploading={uploading}
                handleImport={(e) => setSelectedFile(e.target.files[0])}
              />
            </div>

            {error &&
              <div className="text-lef mt-2 wght-semibold">
                <span className="relative text-red">
                  {error}
                </span>
              </div>
            }
          </div>
        }
      </Loader >
    </div >
  )
}

export default ObservationsImport