import React, { useState, useEffect } from 'react'

const Spacer = ({
  options: { label },
}) => {
  return (
    <div className='mt-8 border-b'>
      <label>{label}</label>
    </div>
  )
}

export default Spacer
