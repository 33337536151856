import React, { useEffect, useState } from 'react'
import {
  Switch,
  Route,
  NavLink,
  useHistory,
} from 'react-router-dom'
import Cookies from 'js-cookie'

import Edit from './Edit'
import List from './List'
import Dashboard from './Dashboard'
import Container from './Container'
import { GlobalConfig } from './Store/'
import ObservationsImport from "./ObservationsImport"

const Main = () => {

  const [appConfig, setAppConfig] = useState(false)
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}config-config`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setAppConfig(response.body.config)
        } else {
          if (response.status === 403) {
            Cookies.remove('token')
            history.push('/login')
          }
        }
      })
  }, [])

  const deploy = async () => {
    if (
      window.confirm(
        '##Deploy##\n\nYou should only deploy once everything is correct.'
      )
    ) {
      await fetch(appConfig.deploy.url, {
        method: 'POST',
        body: {},
      }).then(() => console.log('Deployed'));
      await fetch(appConfig.deploy.urlStaging, {
        method: 'POST',
        body: {},
      }).then(() => console.log('Deployed'));
      alert('It might take a while to publish the changes...');
    }
  };

  const history = useHistory()
  const logout = () => {
    Cookies.remove('token')
    Cookies.remove('user')
    history.replace('/login')
  }
  let username = 'Anonymous'
  if (Cookies.get('user') && Cookies.get('user') !== 'undefined') {
    const userData = JSON.parse(Cookies.get('user'))
    if (userData) {
      username = userData.name
    }
  }

  const isMenuItemActive = item => {
    let active = false
    if (typeof item.items !== 'undefined') {
      item.items.forEach(subitem => {
        const section = history.location.pathname.split('/')[2]
        if (!active && section === subitem.model) {
          active = true
        }
      })
    }
    if (!active) {
      return history.location.pathname.split('/').find(i => i === item.model)
    }
    return active
  }

  const Menu = () => (
    <>
      <div className="md:flex-1 md:overflow-scroll">
        <h1 className="hidden px-4 pb-4 md:block wght-bold">
          <NavLink to="/">{appConfig && appConfig.app.title}</NavLink>
        </h1>
        {appConfig &&
          <ul className="flex md:block">
            {appConfig.menu.map((item, index) => (
              <li key={index} className="mr-4 md:border-b md:border-grayLight md:mr-0" key={item.model}>
                <NavLink className={`border-solid hover:text-primary border-primary md:block md:p-4 hover:wght-semibold`} isActive={() => isMenuItemActive(item)} activeClassName="text-primaryDark wght-semibold md:border-r-4 md:bg-primaryLight" to={item.path || `/admin/list/${item.model}`}>
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        }
        {appConfig && appConfig.deploy && <div className="hidden px-4 my-4 wght-semibold md:block">
          <button onClick={deploy} className="inline-block px-2 py-1 text-xs text-center border rounded cursor-pointer text-primaryLighter bg-primary hover:bg-white border-primary hover:text-primary" to="/admin/deploy">
            Publish
          </button>
        </div>}
      </div>
      <div className="hidden text-xs md:px-4 md:block">
        <p>👋 Hi <span className="capitalize">{username}</span>. <span onClick={logout} className="cursor-pointer wght-bold hover:wght-semibold">Log out</span></p>
      </div>
    </>
  )

  const [pathNameArr, setPathNameArr] = useState(history.location.pathname.split('/'))

  useEffect(() => {
    setPathNameArr(history.location.pathname.split('/'))
  }, [history.location.pathname])

  return (
    <div className="flex flex-col min-h-screen">
      <div className="md:flex">
        <nav className="px-4 py-4 bg-white md:px-0 md:h-screen md:fixed md:w-2/12 md:py-8 md:border-r border-grayLight md:flex md:flex-col">
          <Menu />
        </nav>
        <div className="hidden md:block md:w-2/12"></div>
        <div className="py-12 md:w-10/12">
          <div className="mb-8">
            {appConfig && appConfig.menu.map(item => {
              if (isMenuItemActive(item)) {
                return (
                  <Container key={item.model}>
                    <ul className="flex text-sm">
                      {
                        item.items && item.items.map((subitem, index) => (
                          <li key={index} className="mr-4">
                            <NavLink className={`hover:text-primary hover:wght-semibold ${pathNameArr.includes(subitem.model) ? 'wght-semibold' : ''}`} activeClassName="wght-semibold" to={subitem.path || `/admin/list/${subitem.model}`}>
                              {subitem.title}
                            </NavLink>
                          </li>
                        ))
                      }
                    </ul>
                  </Container>
                )
              }
              return null
            })}
          </div>
          <GlobalConfig config={appConfig}>
            <Container>
              <Switch>
                <Route path="/admin" exact>
                  <Dashboard />
                </Route>
                <Route path="/admin/:groupName?/list/ObservationsImport/:page?">
                  <ObservationsImport />
                </Route>
                <Route path="/admin/:groupName?/list/:name/:page?">
                  <List />
                </Route>
                <Route path="/admin/:groupName?/edit/:name/:id?">
                  <Edit />
                </Route>
              </Switch>
            </Container>
          </GlobalConfig>
          <div className="p-4 mt-4 text-xs md:hidden">
            <p>👋 Hi <span className="capitalize">{username}</span>. Want to <span onClick={logout} className="cursor-pointer wght-bold hover:wght-semibold">log out</span>?</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Main
