import React from 'react'

const Import = ({ uploading, handleImport, disabled }) => {
  return (
    <div>
      {uploading ? (
        <div className="px-2 py-1 mr-2 text-xs rounded text-primaryLighter bg-gray">
          Uploading...
        </div>
      ) : (
        <div className='flex'>
          <button
            className={disabled
              ? "inline-block px-2 py-1 text-xs text-center border rounded pointer-events-none text-primaryLighter bg-gray" :
              "inline-block px-2 py-1 text-xs text-center border rounded cursor-pointer text-primaryLighter bg-primary hover:bg-white border-primary hover:text-primary"}
            to="/admin/deploy"
          >
            <label className='cursor-pointer'>
              Import
              <input
                disabled={disabled}
                onChange={handleImport}
                type="file"
                name="file"
                value=""
                title="&nbsp;"
                style={{ display: 'none' }}
              />
            </label>
          </button>
        </div>
      )}
    </div>
  )
}

export default Import
