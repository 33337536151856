import React, { useState, useEffect } from 'react'
import {
  useParams,
  useHistory,
  useLocation,
  Link,
} from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import useLocalStorage from '../hooks/useLocalStorage'
import Button from './Button'

const List = () => {
  const history = useHistory()
  const location = useLocation()
  const { name: modelName, groupName, page: pageParam } = useParams()
  const [data, setData] = useState(false)
  const [userSortSettings, setUserSortSettings] = useLocalStorage('user_sort_settings', {})
  const [sortedValue, setSortedValue] = useState()
  const [sortAscending, setSortAscending] = useState(true)
  const [sortedItems, setSortedItems] = useState()
  const [title, setTitle] = useState(modelName)
  const [tabTitle, setTabTitle] = useState(modelName)
  const [duplicatedId, setDuplicatedId] = useState(false)
  const [page, setPage] = useState(pageParam - 1 || 0)
  const pageLimit = 20
  const [hasMorePages, setHasMorePages] = useState(false)
  const [loading, setLoading] = useState(true)
  const [newIndex, setNewIndex] = useState()
  const hiddenFields = ['_id', 'deleted']
    
  useEffect(() => {
    setData()
    if (!location.state || !location.state.prevPath || location.state.prevPath !== 'edit') {
      setPage(0)
    }
  }, [modelName])
    
  useEffect(() => {
    setLoading(true)
    getData()

    const urlParts = history.location.pathname.split(modelName)
    if (page) {
      const updatePageUrl = `${urlParts[0]}${modelName}/${page + 1}`
      history.replace({ pathname: updatePageUrl})
    } else {
      const updatePageUrl = `${urlParts[0]}${modelName}`
      history.replace({ pathname: updatePageUrl})
    }
  }, [page, modelName, duplicatedId])

  const getData = () => {
  
    fetch(`${process.env.REACT_APP_API_URL}model-list`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
      body: JSON.stringify({
        modelName,
        page,
        pageLimit,
      }),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200 && !response.body.errorMessage) {
          setData(false)
          setTitle(`List ${response.body.modelConfig.title}`)
          setTabTitle(`List ${response.body.modelConfig.title} - ${response.body.config.app.title}`)
          const rawBody = {...response.body}
          Object.keys(rawBody.modelConfig.list).map(key => {
            if (typeof rawBody.modelConfig.list[key] === 'object' ) {
              rawBody.modelConfig.list[key] = rawBody.modelConfig.list[key].label
            }
          })
          setData(rawBody)
          setSortAscending(Object.values(rawBody.modelConfig.sort)[0] === 'asc' ? true : false)
          setSortedValue(Object.keys(rawBody.modelConfig.sort)[0])
          setHasMorePages(response.body.items.length === response.body.pageLimit)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('token')
            history.push('/login')
          }
        }
      })
  }

  const duplicate = id => {
    if (window.confirm('Duplicate?')) {
      fetch(`${process.env.REACT_APP_API_URL}model-duplicate`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
        body: JSON.stringify({
          modelName,
          _id: id,
        })
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            setDuplicatedId(response.body._id)
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('token')
              history.push('/login')
            }
          }
        })
    }
  }

  const remove = id => {
    if (window.confirm('Delete?')) {
      fetch(`${process.env.REACT_APP_API_URL}model-delete`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
        body: JSON.stringify({
          modelName,
          _id: id,
        })
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            data.items = data.items.filter(item => item._id !== id)
            setData({ ...data })
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('token')
              history.push('/login')
            }
          }
        })
    }
  }

  useEffect(() => {
    const settings = {...userSortSettings}
    if (settings.hasOwnProperty(modelName)) {
      const { value, ascending } = settings[modelName]
      setSortedValue(value)
      setSortAscending(ascending)
    }
    setUserSortSettings(settings)
  }, [modelName])

  useEffect(() => {
    if (sortedItems) {
      setNewIndex(sortedItems.length+1)
    }
  }, [sortedItems])

  useEffect(() => {
    const settings = {...userSortSettings}
    if (typeof sortedValue === 'undefined' || typeof sortAscending === 'undefined') {
      delete settings[modelName] 
    } else {
      settings[modelName] = {
        value: sortedValue,
        ascending: sortAscending,
      }
    }
    setUserSortSettings(settings)
  }, [sortedValue, sortAscending])

  const handleSort = key => {
    if (sortedValue === key) {
      if (sortAscending) {
        setSortAscending(false)
      } else {
        setSortedValue(undefined)
        setSortAscending(undefined)
      }
    } else {
      setSortedValue(key)
      setSortAscending(true)
    }
  }

  useEffect(() => {
    if (typeof data === 'object' && typeof data.items !== 'undefined') {
      const items = [...data.items]
      if (typeof sortedValue !== 'undefined') {
        const sorted = items.sort((a, b) => {
          let itemA = typeof a[sortedValue] == 'string' ? a[sortedValue].toLowerCase() : undefined
          let itemB = typeof b[sortedValue] == 'string' ? b[sortedValue].toLowerCase() : undefined

          if (sortedValue === 'position') {
            itemA = Number(a[sortedValue])
            itemB = Number(b[sortedValue])
          }

          if (typeof itemA === 'undefined' && typeof itemB === 'undefined') return 0
          if (typeof itemA === 'undefined') return sortAscending ? 1 : -1
          if (typeof itemB === 'undefined') return sortAscending ? -1 : 1

          if (itemA < itemB) return sortAscending ? -1 : 1
          if (itemA > itemB) return sortAscending ? 1 : -1
          return 0
        })
        setSortedItems(sorted)
      } else {
        setSortedItems(data.items)
      }
    }
  }, [data, sortedValue, sortAscending, modelName])

  const Actions = ({ id, modelName, index }) => {
    if (modelName === 'Observation') {
      if (!data.items[index]) return <></>
      return (
        <div className='flex'>
          <Button
            size="small"
            className='mr-2'
            onClick={() => {
              window.open(`${process.env.REACT_APP_PUBLIC_URL}en/${data.items[index].observationType}-observation/view/${data.items[index]?.observationId}`, '_blank')
            }}
          >
            View
          </Button>
          <Button
            size="small"
            className='flex justify-center w-16'
            color={data.items[index].deleted ? "success" : "cancel"}
            onClick={() => updateObservation({ id: data.items[index]?.observationId , options: { deleted: !data.items[index].deleted } })}
          >
            {
              uploadingDeleted === data.items[index]?.observationId ?
              <span className="flex items-center">
                {  data.items[index].deleted ? "Recovering..." : "Deleting..." }
              </span> : data.items[index].deleted ? "Recover" : "Delete"
            }
          </Button>
        </div>
      )
    }
    return (
      <div className="relative flex text-xs">
        <Link className="mr-4 cursor-pointer hover:text-primary hover:wght-semibold" to={{ pathname: `/admin/${(groupName) ? groupName+'/' : ''}edit/${modelName}/${id}`, state: { index: (index+1) * 10, page }}}>Edit</Link>
        <span className="block mr-4 cursor-pointer hover:text-primary hover:wght-semibold" onClick={() => duplicate(id)}>Clone</span>
        <span className="block cursor-pointer hover:text-primary hover:wght-semibold" onClick={() => remove(id)}>Delete</span>
      </div>
    )
  }

  const [uploadingVerified, setUploadingVerified] = useState(false)
  const [uploadingDeleted, setUploadingDeleted] = useState(false)

  const updateObservation = async ({ id, options }) => {
    if (uploadingDeleted || uploadingVerified) return

    if (options.hasOwnProperty('observationVerified')) setUploadingVerified(id)
    if (options.hasOwnProperty('deleted')) setUploadingDeleted(id)

    await fetch(`${process.env.REACT_APP_CONTROLLER_URL}edit-observation`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
      body: JSON.stringify({
        observationId: id,
        ...options,
      })
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setUploadingVerified(false)
        setUploadingDeleted(false)
        if (response.status === 200) {
          data.items = data.items.map(item => {
            if (item?.observationId === id) {
              item = {...item, ...options}
            }
            return item
          })
          setData({ ...data })
        } else {
          console.log('Error', response.status, response.body.message)
        }
      })
  }

  return (
    <div>
      <Helmet>
        <title>{tabTitle}</title>
      </Helmet>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl wght-semibold">{title}</h2>
        <Link to={{ pathname: `/admin/${(groupName) ? groupName+'/' : ''}edit/${modelName}`, state: { index: newIndex * 10 }}} className="px-2 py-1 text-xs rounded cursor-pointer text-primaryLighter bg-primary hover:wght-semibold">Add</Link>
      </div>
      {data && data.items.length === 0 && <div>Nothing to list</div>}
      {data && data.items.length > 0 && sortedItems && <table className="w-full">
        <thead className="w-full text-left bg-white border-b border-grayLight">
          <tr>
            {Object.keys(data.modelConfig.list).map(key => !hiddenFields.includes(key) && (
              <th key={key} className="px-4 py-2">
                <span
                  className="relative cursor-pointer wght-semibold"
                  onClick={() => handleSort(key)}
                >
                  {data.modelConfig.list[key]}
                  {sortedValue === key && (
                    <span className="absolute inset-y-0 right-0 flex items-center justify-center -mr-4 text-center">
                      {sortAscending ? "↑" : "↓"}
                    </span>
                  )}
                </span>
              </th>
            ))}
            <th className="px-4 py-2 text-right wght-semibold">Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedItems.map((item, index) => <tr key={item._id} className={`relative  border-b border-grayLight hover:bg-primaryLight ${item.deleted ? 'text-red bg-grayLighter' : 'bg-white'}`}>
            {Object.keys(data.modelConfig.list).map(key => {
              if (hiddenFields.includes(key)) return <></>
              if (key === 'observationVerified') return <td key={key} className="px-4 py-2">
                <Button
                  size="small"
                  className='flex justify-center w-24'
                  color={item[key] === "1" ? "invertedGreen" : "cancel"}
                  onClick={() => updateObservation({ id: item?.observationId , options: { observationVerified: item[key] === "1" ? "0" : "1" } })}
                >
                  <span>
                    {  uploadingVerified === item?.observationId ?
                      <span className="flex items-center">
                        {  item[key] === "1" ? "Unverifying..." : "Verifying..." }
                      </span> : item[key] === "1" ? "Verified" : "Not verified"
                    }
                  </span>
                </Button>
              </td>
              return <td key={key} className="px-4 py-2">
                {typeof item[key] !== 'undefined' && item[key]}
              </td>
            })}
            <td className="table-cell px-4 py-2 text-right">
              <div className="flex justify-end">
                <Actions modelName={modelName} id={item._id} index={index} />
              </div>
            </td>
          </tr>)}
        </tbody>
      </table>}
      {loading ? <div className="mt-8">Loading...</div> :
        (hasMorePages || page > 0) &&
          <div className="flex justify-between mt-8">
            <div>
              {page > 0 && <span onClick={() => setPage(page - 1)} className="ml-1 cursor-pointer hover:wght-semibold">Previous page</span>}
            </div>
            {hasMorePages && 
              <span onClick={() => setPage(page + 1)} className="mr-1 cursor-pointer hover:wght-semibold">Next page</span>
            }
          </div>
      }
    </div>
  )
}

export default List